import type { JSX } from 'react';
import { HeroCarousel } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CarouselProps = SliceComponentProps<Content.CarouselSlice>;

const Carousel = ({ slice }: CarouselProps): JSX.Element => {
  return <HeroCarousel {...slice} />;
};

export default Carousel;
